import * as React from "react";
import LayoutComponent from "../components/LayoutComponent/LayoutComponent";
import "../styles/index.styles.scss";

const IndexPage = () => (
  <LayoutComponent>
    <div className="text">Willkommen bei der Familie Faber</div>
    <div className="link">
      Background Photo by{" "}
      <a
        href="https://unsplash.com/@ninjason?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        target="_blank"
        rel="noopener noreferrer"
      >
        Jason Leung
      </a>{" "}
      on{" "}
      <a
        href="https://unsplash.com/s/photos/color?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        target="_blank"
        rel="noopener noreferrer"
      >
        Unsplash
      </a>
    </div>
  </LayoutComponent>
);

export default IndexPage;
